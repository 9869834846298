<template>
  <section class="light-courses">
    <div v-if="loaded">
      <b-row>
        <b-col md order-md="0" order="1" class="mt-3 mt-md-0">
          <LightCourseTeaser v-for="(c, cind) in courses"
                             :key="`course-${cind}`"
                             class="mb-3"
                             :course="c"/>
        </b-col>
        <b-col md="5" lg="4" order-md="1" order="0">
          <div class="spacecard border-0" v-if="generalProgress">
            <h3 class="text-center">{{ $t('my_accomplishments') }}</h3>
            <b-row>
              <b-col md="12" cols="4">
                <div class="text-center mt-md-4">
                  <ProgressPie :progress="generalProgress.progress"
                               :mobile-mini="true"
                               size="large"/>
                </div>
                <hr class="d-md-block d-none"/>
              </b-col>
              <b-col md="12" cols="4">
                <ScoreBoard :label="$t('score')" :score="generalProgress.score"/>
                <hr class="d-md-block d-none"/>
              </b-col>
              <b-col md="12" cols="4">
                <ScoreBoard :label="$t('words')" :score="generalProgress.words"/>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>

    </div>
  </section>
</template>

<script>
import { StudentPageService } from "../../../../services/api.service"
import LightCourseTeaser from "../../../parts/courses/LightCourseTeaser.vue"
import ProgressPie from "../../../parts/general/ProgressPie.vue"
import ScoreBoard from "../../../parts/courses/ScoreBoard.vue"

export default {
  name: "StudentLightCourses",
  components: {
    ScoreBoard,
    ProgressPie,
    LightCourseTeaser
  },
  data() {
    return {
      courses: [],
      generalProgress: null,
      loaded: false
    }
  },
  methods: {
    async loadStudentCourses() {
      const res = await StudentPageService.getCourses('light')
      this.courses = res?.data?.data?.assignments || []
      this.generalProgress = res?.data?.data?.generalProgress || null
      this.loaded = true
    }
  },
  mounted() {
    this.loadStudentCourses()
  }
}
</script>

<style scoped>
h3 {
  font-size: 20px;
}
@media(max-width: 768px) {
  h3 {
    font-size: 18px;
  }
}
</style>
