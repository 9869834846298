import { render, staticRenderFns } from "./StudentLightCourses.vue?vue&type=template&id=0fec7056&scoped=true&"
import script from "./StudentLightCourses.vue?vue&type=script&lang=js&"
export * from "./StudentLightCourses.vue?vue&type=script&lang=js&"
import style0 from "./StudentLightCourses.vue?vue&type=style&index=0&id=0fec7056&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fec7056",
  null
  
)

export default component.exports