<template>
  <div class="light-course-teaser">
    <b-row class="align-items-end">
      <b-col md cols="12">
        <div class="content">
          <div>
            <div v-if="progress < 100">
              <span class="course-badge"><JoystickSvg/>{{ $t('lightcourse') }}</span>
              <span class="progress-badge">{{ progress }}%</span>
            </div>
            <div v-else>
              <span class="course-badge finished"><TickSvg/>{{ $t('finished') }}</span>
            </div>
          </div>
          <h4>{{ lightCourse.title }}</h4>
          <div class="mt-5">
            <b-button variant="text"
                      class="pl-0 text-primary font-weight-bold"
                      :to="{ name: 'StudentLightCourseModules', params: { courseId: lightCourse.id } }">
              <span v-if="progress == 0">{{ $t('start') }}</span>
              <span v-else-if="progress == 100" class="text-muted">{{ $t('open') }}</span>
              <span v-else>{{ $t('continue') }}</span>
            </b-button>
          </div>
        </div>
      </b-col>
      <b-col md="auto" cols="12">
        <div class="img-wrapper">
          <img :src="image" alt=""/>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import JoystickSvg from '@/assets/svg/joystick.svg'
import TickSvg from '@/assets/svg/tick.svg'

export default {
  name: "LightCourseTeaser",
  components: {
    JoystickSvg,
    TickSvg
  },
  props: {
    course: {
      type: Object,
      required: true
    }
  },
  computed: {
    lightCourse() {
      return this.course?.light_course || {}
    },
    image() {
      return this.lightCourse.image || '/images/course_placeholder.png'
    },
    progress() {
      return this.course.progress
      // if(this.course.studentProgress == 100) return 'finished'
      // if(this.course.studentProgress == 0 || !this.course.studentProgress) return 'not_started'
      // return 'in_progress'
    }
  }
}
</script>

<style scoped lang="scss">
.light-course-teaser {
  background: white;
  position: relative;
  border-radius: 16px;
  .content {
    padding: 12px 22px;
    .course-badge, .progress-badge {
      display: inline-block;
      color: white;
      margin-right: 4px;
      padding: 6px 10px;
      border-radius: 7px;
      font-weight: 700;
      font-size: 11px;
      svg {
        width: 16px;
        fill: white;
        margin-right: 4px;
      }
    }
    .course-badge {
      background: #C887FF;
      &.finished {
        svg {
          margin-right: 0;
        }
        background: #27AE60;
      }
    }
    .progress-badge {
      background: #ECC03B;
    }
    h4 {
      font-weight: 700;
      font-size: 18px;
      margin-top: 12px;
    }
  }
  .img-wrapper {
    padding-right: 24px;
    img {
      width: 140px;
      border-top-left-radius: 100%;
      border-top-right-radius: 100%;
    }
  }
}

@media(max-width: 768px) {
  .img-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
</style>
